
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    qa: false,
    dev: true,
    isDebugMode: true
};

let PROVIDERS: any[] = [
    /**
     * Common env directives
     */
];

PROVIDERS = [
    ...PROVIDERS,
    { provide: 'AUTH_URL', useValue: 'https://gateway-dev.phoenix-apothekenportal.de' },
    { provide: 'API_URL', useValue: 'https://gateway-dev.phoenix-apothekenportal.de' },
    // { provide: 'CMS_URL', useValue: 'https://phoenix-web-dev.twt-dh.de' },
    { provide: 'CMS_URL', useValue: 'https://cms-dev.phoenix-apothekenportal.de'},
    { provide: 'ADMIN_MAIL', useValue: 'i.korlevic@phoenixgroup.eu' },
    { provide: 'REQ_DATE_FORMAT', useValue: 'yyyy-MM-dd' },
    { provide: 'VIEW_DATE_FORMAT', useValue: 'yyyy-MM-dd' },
    // deine Apotheke-Store providers
    { provide: 'DA_STORE_URL', useValue: 'https://deineapotheke-store.de/apologin'},
    { provide: 'DA_STORE_REFERRER', useValue: 'apothekenportal'},
    // Privatsortiment (Genuss Kultur) providers
    { provide: 'PS_URL', useValue: 'https://privatsortiment.de/apologin'},
    { provide: 'PS_REFERRER', useValue: 'apothekenportal'},
    // Notfalldepot providers
    {provide: 'ND_URL', useValue: 'https://deineapotheke-store.de/apologin'},
    {provide: 'ND_REFERRER', useValue: 'apothekenportal'},
    {provide: 'ND_FLAG', useValue: 'notfalldepot'},
    {provide: 'STORAGE', useValue: 'https://cspapdsacc.blob.core.windows.net'},
    {provide: 'CDN', useValue: 'https://cspapdsacc.blob.core.windows.net'},



    /**
     * Custom providers in development.
     */
];

export const ENV_PROVIDERS = [
    ...PROVIDERS
];
